import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class FrontPage extends Component {
  static propTypes = {
    prop: PropTypes
  }

  render() {
    let style = {
      width: '100em',
      height:'10em',
      marginLeft:'auto',
      marginRight:'auto',
      justifyContent: 'center',
      display: 'flex'
    };
    let paleBlueStyle = {
      color: 'rgb(13, 238, 238)'
    }
    let yellowStyle = {
      color: 'rgb(238, 238, 13)'
    }
    return (
      <div style={style}>
        <div style={{fontSize: '4em'}}>
          Welcome to the <span style={yellowStyle}>gul<span style={paleBlueStyle}>.</span>ag</span>!
        </div>
        <div>
          This site is still under heavy developement.
        </div>
      </div>
    )
  }
}
