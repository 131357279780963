import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FrontPage from './Views/FrontPage'

export default class Root extends Component {
  static propTypes = {
    prop: PropTypes
  }

  render() {
    let style = {
      display: 'flex',
      justifyConent: 'center',
      flewDirection: 'column',
      // alignItems: 'center',
      marginTop: '10em',
      height: '100%'
    }
    return (
      <div style={style}>
        <FrontPage/>
      </div>
    )
  }
}
